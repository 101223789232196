.salesForm{
    
}


.sale_list,
.taxbill_list{
    font-size:0.8rem;

    .price,
    .date{
        font-size:0.75rem;
    }

    .discount{
        color:$danger;
    }

    tr{
        &:hover{
            td{
                border-top:1px solid $danger !important;
                border-bottom:1px solid $danger ;
            }
        }
        &.unpaind{
            color:#f00;
        }//end unpaid

        .btn{
            font-size:0.75rem;
            padding:0.1rem 0.5rem;
        }
    }
}//

.sale_view_container{
    section{
        .title{
            font-size: 2rem;
            font-weight:bold;
        }
    }

    .sale_info{
        .dl-horizontal{
            $dtWidth:120px;
            $dtMarginright:10px;
            dt{
                padding:2px 0;
                width: $dtWidth;
                @media all and  (max-width: $sm_u) {
                    float:left;
                    clear:left;
                }
            }

            dd{
                margin-left: $dtWidth+$dtMarginright;
                padding:2px 0;

                @media all and (max-width: $sm_u) {
                    float:left;
                    clear:none;
                    margin-left:0;
                    padding:0;
                    padding-left:10px;

                }
            }
        }//.dl-horizontal
    }// .sale_info
}// .sale_view_container

.taxbill_list{
    .taxbill{

    }
}

.BUY_taxbill{
    color:$danger;
}

.SALE_taxbill{
    color:$primaryColor;
}

.complete{
    color:$danger;
}