$color:#2d2d2d;
$fontColor:#333333;
$activeColor:#56d5fa;
$success:#28a745;
$warning:#cac8c1;
$danger:#dc3545;

$primaryColor:#01a6ef;
$gnbActive:#01a6ef;

$ebookColor:#FF6418;



$bottom-diffused: 0px 13px 21px -10px rgba(0, 0, 0, 0.3);
$left-diffused: -13px 0px 21px -10px rgba(0, 0, 0, 0.3);
$right-diffused: 13px 0px 21px -10px rgba(0, 0, 0, 0.3);

$pooq_color:#7f34e1;
$pooq_font_color:#fff;

$ebook_color:#FF6418;
$ebook_font_color:#fff;

$xs_u:319.98px;
$ms_u:353.98px;
$sm_u:575.98px;
$md_u:767.98px;
$lg_u:991.98px;
$xl_u:1199.98px;

$xs:320px;  
$ms:354px;
$sm:576px;
$md:768px;
$lg:992px;
$xl:1200px;