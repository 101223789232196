.libraryList{
    .library{
        border-bottom:1px solid #ddd;
        padding:1rem;
        span{
            margin:1rem;
        }

        .type{
            font-weight:bold;
                &.PUBLIC{
                    color:#60a5ca;
                }

                &.COLLEGE{
                    color:#ca6c6c;
                }

                &.PRIVATE{
                    color:#c7ca6c;
                }

                &.ETC{

                }
        }
    }
}//.libraryList

.libraryMemo{
    .memoList{
        .memo{
            background-color: #fef292;
            margin:0.5rem;
            padding:0;
            display: inline-block;
            vertical-align: top;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
            border: 1px solid #b49f04;
            .header{
                padding: 5px;
                min-height: 41px;
                font-weight: bold;
                background-color: #fee636;
                border-bottom: 1px solid #b49f04;
            }
            textarea{
                width:100%;
                height:100%;
                min-width:280px;
                min-height: 160px;
                resize:both;
                padding:5px;
                background-color: transparent;
                border:none;
            }
        }
    }//end memoList
}// libraryMemo

.librarySite{
    margin:0.3rem;
    padding:0.3rem;
    border:1px solid #eee;
    .siteForm{
        border:1px solid #ddd;
        padding:1rem;
        margin:1rem;
    }
    .siteList{
        max-height: 400px;
        overflow-y: auto;
        .site{
            &.EBOOK{
                .name{
                    background-color: $ebookColor !important;
                }
            }
            .url{
                color:#333;
            }
            .control{
                padding:0.05rem;
                font-size:0.5rem;
                margin:0 0.2rem;
            }
        }
    }
}// .librarySite

.libraryHistory{
    margin:0.3rem;
    padding:0.3rem;
    border:1px solid #eee;
    .historyForm{
        border:1px solid $activeColor;
        border-left:4px solid $activeColor;
        .options{
            padding:0.2rem;
        }
        .controls{
            margin:0.2rem;
            .btn{
                margin:0.2rem;
            }
        }
    }
    .historyList{
        padding:1rem 0;
        max-height: 300px;
        overflow-y: auto;
        .history{
            &.EBOOK{
                border-left: 2px solid $ebookColor;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                .service{
                    color:$ebookColor !important;
                }
            }

            .header{
                .service{
                    font-weight: bold;
                }
                .date{
                    font-style: italic;
                    font-size: 0.7rem;;
                    color:#777;
                    padding:0 0.5rem;
                }

                .name{
                    font-size:0.7rem;
                    line-height: 200%;
                    margin:0 0.5rem;
                }
                .control{
                    font-size: 0.6rem;
                    margin:0 0.15rem;
                    padding:0.1rem 0.4rem;

                }
            }
            


        }
    }
}// .libraryHistory