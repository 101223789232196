
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    height: 100%;

    body {
        height: 100%;
        width: 100%;
        position: relative;
        font-family: 'NanumSquareNeo', 'Nanum Gothic', '나눔고딕', 'Malgun Gothic','맑은 고딕', 'Noto Sans KR', sans-serif, 'dotum','돋움','Apple Gothic','sans-serif';
        font-size: 100%;  
    }
}

*{
    
    box-sizing: border-box;
    margin:0;
    padding:0;
}
img{
    margin:0;
    border:none;
    max-width: 100%;
}
ul, ol{
	list-style:none;
    margin:0;
    padding:0;
}



.fieldValid{
    &.invalid{
        >input,>select{
            border-color:$danger;
        }
        .errorMessage{
            color:$danger;
            font-style: italic;
            font-size:0.8rem;
        }
    }
}// .fieldValid




a{
    color:#333;
    &:link:visited{
        color:#333;
    }
}


table,
.table{
    thead{
        tr{
            th, td{
                
                text-align:center;
                padding:8px 0;
                font-size:.8rem;
                font-weight:bold;
                background-color:#f3f3f3;
                color:#333;
                border-bottom-color:#d8d8d8;

            }
        }
    }

    tbody{
        tr{
            td{
                padding:2px;
                vertical-align: middle;
                line-height:2rem;
            }
        }

    }
}

form{
    .label{
        border:1px solid #f00;
        background-color: #eee;
        color:#333;
        text-align:right;
    }
}



.controls{
    margin:10px;

    display: block;
    clear:both;
    text-align:center;
    form{
        display:inline-block;
    }
}

.control-label{
    font-size:1.2rem;
    padding-left:20px;
    padding-right:0;
}

.form-heading{
    text-align:center;
    margin-top:10px;
    margin-bottom:20px;
}


.form_error{
    color:$danger;
    font-size:0.75em;
    font-style:italic;
    padding:3px;
}

.message_box{
    max-width:500px;
    margin:0 auto;
    margin-top:10%;
    border:1px solid #ddd;
    padding:20px;
    border-radius:10px;
    box-shadow:$bottom-diffused;
}


.dl-horizontal{
    $dtWidth:100px;
    $dtMarginright:10px;
    margin:0;
    dt{
        float: left;
        width: $dtWidth;
        overflow: hidden;
        clear: left;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (max-width: $sm_u) {
            float:none;
            clear:both;
        }
    }

    dd{
        margin-left: $dtWidth+$dtMarginright;
        @media (max-width: $sm_u) {
            margin-left:0;
            clear:both;
        }
    }

}


.panel{
    .panel-heading{
        font-weight:900;
    }
}

.controls{
    &.text-right{
        text-align:right;
    }

}

.clear{
    clear:both;
}


.content_box{
    border:1px solid #ddd;
    background-color:#fff;
    padding:20px 15px;
    display:block;
    width:100%;

    @media (max-width:  $xs_u){
        padding:0;
    }
    @media (max-width: $sm_u) {
        padding:7px;
    }
}



.information_box
{
    width:100%;
    border-top:1px solid #ddd;
    display:inline-block;

    div{
        padding:10px;
        line-height: normal;
        display: block;
        border:1px solid transparent;
        border-bottom-color:#ddd;
    }//div

    .title_label{
        background-color:#efefef;
        border-bottom-color:#ddd;
        border-right-color:#ddd;
        text-align: right;
        clear:both;
    }// .title_label

    .label{
        font-size:1.2rem;
    }

    .help-block{
        padding:0;
    }


}

.no_result{
    text-align:center;
    padding:10px;
    margin:20px auto;
    font-size:2em;
}

.label{
    &.pooq{
        background-color:$pooq_color;
        color:$pooq_font_color;

        .badge{
            color:$pooq_color;
            border:1px solid $pooq_color;
            background-color:#fff;
        }
    }


    &.ebook{
        background-color:$ebook_color;
        color:$ebook_font_color;
    }

    &.bookcube{
        background-color:$ebook_color;
        color:$ebook_font_color;
    }



}

.business_status{
    font-weight:bold;

    &.business_status_0{
        color:#538ac8;
    }

    &.business_status_1{
        color:#71ad48;
    }

    &.business_status_2{
        color:#efb734;
    }

    &.business_status_3{
        color:#a62428;
    }
}

.form-group{
    p{
        line-height:36px;
        margin:0;
    }
}

.search_box{
    text-align:center;
    margin:10px auto;
    .form-group{
        padding:0 5px;
        @media (max-width: $sm_u) {
            display:inline-block;
        }
    }

    @media (max-width: $sm_u) {
        #page_q{
            width:80%;
            float:left;
        }
    }
}




.label{
    .badge{
        margin-left:10px;
    }
}

.help-block:before{
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: Glyphicons Halflings;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color:#8e8e8e;
    content: "\E086";
    padding-right:5px;
}

.text-block{
    display:block;
    width: 100%;
    height: 36px;
    padding: 6px 12px;
    background-color: #fff;
    background-image: none;
    font-size: 14px;
    line-height: 1.6;
}


.padding_zero{
    padding:0 !important;
}

.btn_destroy{
    color:$danger;
    border:1px solid$danger;
    background-color:#fff;

}

.datepicker{
    cursor: pointer;
    background-color:#fafafa !important;
}
.price_only{
    ime-mode: disabled;
    padding:5px;
}