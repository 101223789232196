.readingVideoList{
    .video{
        .state{
            color:#999;
            &.ACTIVE{
                color:#000;
            }
            &.WARNING{
                color:$warning;
            }
        }
        .controls{
            padding:0.2rem;
            margin:0;
            .btn{
                margin:0.2rem;
                font-size:0.75rem;
                padding:0.1rem 0.4rem;
            }
        }
    }
}// .readingVideoList